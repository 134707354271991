import { css } from 'astroturf';
import React, { useEffect, useState } from 'react';
import Confetti from 'react-dom-confetti';
import { Helmet } from 'react-helmet';

import PageHeader from '../components/PageHeader';
import Questionaire from '../components/Questionaire';
import SEO from '../components/SEO';
import Link from '../components/link';
import Layout from '../layout';

const styles = css`
  .downloadPage {
    text-align: center;
    padding-bottom: 6rem;

    & > p {
      padding: 0 1rem;

      & + p {
        margin-top: 2rem;
      }
    }
  }
  .nextup {
    max-width: 36rem;
    margin: 0 auto;
    padding: 2rem 2rem 1.5rem;
    overflow: hidden;
    background: #fffa;
    box-shadow: inset 0px 0px 6px #0132;
    border-radius: 0.5rem;
    border: 1px solid #0001;

    & p {
      text-wrap: balance;
      margin-bottom: 2rem;
    }

    & a {
      margin: 0;
      padding: 0;
      background: 0;

      & > span {
        width: 120px;
        display: block;
        margin: 0.25rem auto 0;
        background: rgb(29, 155, 240);
        color: #fff;
        font-weight: bold;
        border-radius: 0.5rem;
        padding: 0.25rem 0.5rem;
        float: right;
      }

      &:hocus > span {
        background: rgb(26, 140, 216);
      }
    }

    & small {
      line-height: 32px;
      opacity: 0.75;
      float: right;
      margin-top: 0.25rem;
    }
  }

  .confetticontainer {
    width: 100%;
    max-width: 840px;
    display: flex;
    justify-content: space-between;
    height: 0;
    margin: 0 auto;
    transform: translateY(-100px);

    @media (prefers-reduced-motion: reduce) {
      display: none;
    }
  }

  .tweetholder {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;

    & div.post {
      position: relative;
      background: #fff;
      border-radius: 0.5rem;
      padding: 0.5rem 1rem;
      filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.5));
      text-align: left;
      color: #000;

      &::before {
        content: ' ';
        position: absolute;
        left: -0.5rem;
        bottom: calc(50% - 0.5rem);
        width: 0;
        height: 0;
        border-top: 0.5rem solid transparent;
        border-right: 0.5rem solid white;
        border-bottom: 0.5rem solid transparent;
      }

      & span {
        color: hsl(204, 88%, 53%);
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: bold;
    gap: 1rem;
    margin-top: 1rem;

    & a {
      display: block;
      padding: 0.25rem 1rem;
      border-radius: 0.5rem;
      background: rgb(16, 125, 181);
      color: #fff;
      font-weight: bold;
      text-decoration: none;
      transition: background 0.15s;
    }

    & a:hover {
      transition: background 0.15s;
      background: rgb(103, 178, 111);
    }

    & svg {
      width: 1em;
      height: 1em;
      fill: currentColor;
      vertical-align: baseline;
      margin: 0 0.5rem;
    }
  }
  .icons {
    display: flex;
    align-items: center;
    flex-direction: column;

    & svg {
      flex: 0 0 32px;
      width: 32px;
      height: 32px;
      fill: hsl(204, 88%, 53%);
    }
  }
`;

const config = {
  angle: 100,
  spread: 120,
  startVelocity: '40',
  elementCount: '150',
  dragFriction: '0.1',
  duration: 9000,
  stagger: '3',
  width: '16px',
  height: '16px',
  colors: ['#b51010', '#b5107d', '#107db5', '#10b57d', '#7d10b5', '#7db510'],
  // colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
};

const text =        `Just%20improved%20my%20web%20dev%20workflow%20by%20getting%20a%20%40polypane.app%20subscription!%20%F0%9F%A5%B3%20%F0%9F%94%A5%20%F0%9F%A4%A9%20https://polypane.app`;
const twitterText = `Just%20improved%20my%20web%20dev%20workflow%20by%20getting%20a%20%40polypane%20subscription!%20%F0%9F%A5%B3%20%F0%9F%94%A5%20%F0%9F%A4%A9`

const bksylink = `https://bsky.app/intent/compose?text=${text}`;
const twitterLink = `https://twitter.com/intent/tweet?related=polypane&text=${twitterText}&url=https%3A%2F%2Fpolypane.app&original_referer=https://polypane.app/thanks/`

const Page = (props) => {
  const [confetti, setConfetti] = useState(false);

  useEffect(() => {
    setConfetti(true);
  }, []);
  return (
    <Layout>
      <SEO title="Thanks for subscribing" keywords={['']} pathname={props.location.pathname} />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className={styles.confetticontainer}>
        <Confetti active={confetti} config={config} />
        <Confetti active={confetti} config={config} />
      </div>
      <PageHeader>
        <h1>Thank you for subscribing! 🎉️</h1>
        <p>
          You can manage your subscription and account in the <Link to="https://dashboard.polypane.app">Dashboard</Link>
          .
        </p>
      </PageHeader>

      <div className={styles.downloadPage}>
        <div className={styles.nextup}>
          <h2>Share on Bluesky or X/Twitter</h2>
          <p>Celebrate getting a subscription by sharing it out, it really helps get the word out! ❤️</p>

          <div className={styles.tweetholder}>
            <div className={styles.icons}>
              <svg data-tip="Bluesky" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <title>Bluesky</title>
                <path d="M6.335 5.144c-1.654 -1.199 -4.335 -2.127 -4.335 .826c0 .59 .35 4.953 .556 5.661c.713 2.463 3.13 2.75 5.444 2.369c-4.045 .665 -4.889 3.208 -2.667 5.41c1.03 1.018 1.913 1.59 2.667 1.59c2 0 3.134 -2.769 3.5 -3.5c.333 -.667 .5 -1.167 .5 -1.5c0 .333 .167 .833 .5 1.5c.366 .731 1.5 3.5 3.5 3.5c.754 0 1.637 -.571 2.667 -1.59c2.222 -2.203 1.378 -4.746 -2.667 -5.41c2.314 .38 4.73 .094 5.444 -2.369c.206 -.708 .556 -5.072 .556 -5.661c0 -2.953 -2.68 -2.025 -4.335 -.826c-2.293 1.662 -4.76 5.048 -5.665 6.856c-.905 -1.808 -3.372 -5.194 -5.665 -6.856z" />
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
              </svg>
            </div>
            <div className={styles.post}>
              Just improved my web dev workflow by getting a <span>@polypane</span> subscription! 🥳 🔥 🤩
            </div>
          </div>
          <div className={styles.buttons}>
            Share on:
            <a href={bksylink}>Bluesky</a>
            <a href={twitterLink}>
              X/Twitter
            </a>
          </div>
          <small>You can change the message before posting</small>
        </div>
        <script async src="https://platform.twitter.com/widgets.js" />
        <Questionaire />
      </div>
    </Layout>
  );
};

export default Page;
